import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { 
  updateProfile, 
  getAuth, 
  signInWithEmailAndPassword, 
  onAuthStateChanged, 
  reauthenticateWithCredential,  
  EmailAuthProvider              
} from "firebase/auth";

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyD55qDXrhi7fNjKLOUZkDVBtCABGUmzCyk",
  authDomain: "inmuebles-1749f.firebaseapp.com",
  projectId: "inmuebles-1749f",
  storageBucket: "inmuebles-1749f.appspot.com",
  messagingSenderId: "463162182432",
  appId: "1:463162182432:web:e002181c4acf18fe906545",
  databaseURL: "https://inmuebles-1749f-default-rtdb.firebaseio.com" // Añade tu URL de Firebase Realtime Database
};

// Inicializar Firebase y Realtime Database
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

// Exportar las funciones necesarias
export { 
  app, 
  database, 
  updateProfile, 
  getAuth, 
  signInWithEmailAndPassword, 
  onAuthStateChanged, 
  reauthenticateWithCredential,  
  EmailAuthProvider              
};
