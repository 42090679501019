<template>
    <div>
        <v-app-bar flat height="40" color="#2271b1" style="color:white">
              <div style="margin:0 auto;display:block;">
                <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" color="white"
                v-on="on"
                icon text @click="nav = !nav"><i class="fas fa-bars"></i></v-btn>
            </template>
            <span>Desplegar Menu</span>
            </v-tooltip>

            <span style="font-size:10px;">
              InmoGlobal
            </span>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" color="white"
                v-on="on"
                icon text @click="perfilMenu = !perfilMenu"><i class="far fa-sun"></i></v-btn>
            </template>
            <span>Opciones</span>
            </v-tooltip>
              </div>
        </v-app-bar>

        <v-navigation-drawer v-model="perfilMenu" fixed app right width="200">
          <div style="padding:30px 10px 10px 10px;">
            👋 <span style="font-size:12px">
              Hola, <strong>{{usuario.username}}</strong>.
            </span>
          </div>

          <v-list
          nav
          dense
        >
          <v-list-item-group
            v-model="selectedItem"
            color="#2271b1cc"
          >
            <v-list-item
              v-for="(item, i) in itemsPerfil"
              :key="i"
              :to="item.sitio"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <v-divider style="margin:0 auto;display:block;width:90%;" class="my-5"></v-divider>

          <v-list-item-group
          >
            <v-list-item
              @click="signOut()"
            >
              <v-list-item-content>
                <v-list-item-title v-text="closss" 
                style="border:solid 1px red;border-radius:10px;padding:5px"
                class="red--text text-center"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        </v-navigation-drawer>



        <v-navigation-drawer v-model="nav" fixed app v-if="usuario != null" left width="300">
            <v-img style="margin:30px auto;display:block;" width="100" src="https://inmoglobalve.com.ve/img/logoAz.png"></v-img>
            <div style="width:95%;margin:20px auto;display:block;">


              <v-list dense shaped v-if="usuario.rank == 1">

              
          <v-list-item to="/main" color="#2271b1" v-if="usuario.access == '200'">
            <v-list-item-title>Main</v-list-item-title>
          </v-list-item>


          <v-list-group color="#2271b1"
          append-icon="fas fa-arrow-circle-down"
              v-for="item in contactoAdmin"
              :key="item.title"
              no-action
          >
              <template v-slot:activator>
              <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
              </template>

              <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="child.link"
              >
              <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
              </v-list-item>
            </v-list-group>


            <v-list-group
                      color="#2271b1"
                      append-icon="fas fa-sort-down"
                          >
                            <template v-slot:activator>
                              <v-list-item-title>Reportes</v-list-item-title>
                            </template>

                            <v-list-group
                              color="#2271b1"
                              append-icon="fas fa-sort-down"
                              no-action
                              sub-group
                              v-for="(item, i) in Reportes" :key="i"
                            >
                              <template v-slot:activator>
                                <v-list-item-content>
                                  <v-list-item-title>{{item.title}}</v-list-item-title>
                                </v-list-item-content>
                              </template>

                              <v-list-item color="#00174A"
                                v-for="(child, i) in item.items"
                                :key="i"
                                @click="irReporteProducto(child.link, child.is)"
                              >
                                <v-list-item-title v-text="child.title"></v-list-item-title>
                              </v-list-item>
                            </v-list-group>
                        </v-list-group>



            </v-list>


            <v-list dense shaped v-if="usuario.rank == 2">


            <v-list-group color="#2271b1"
            append-icon="fas fa-sort-down"
                v-for="item in contactoAsesor"
                :key="item.title"
                no-action
            >
                <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
                </template>

                <v-list-item
                v-for="child in item.items"
                :key="child.title"
                :to="child.link"
                >
                <v-list-item-content>
                    <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
                </v-list-item>


                
            </v-list-group>
            <v-list-group
                        color="#2271b1"
                        append-icon="fas fa-sort-down"
                            >
                              <template v-slot:activator>
                                <v-list-item-title>Reportes</v-list-item-title>
                              </template>

                              <v-list-group
                                color="#2271b1"
                                append-icon="fas fa-sort-down"
                                no-action
                                sub-group
                                v-for="(item, i) in Reportes" :key="i"
                              >
                                <template v-slot:activator>
                                  <v-list-item-content>
                                    <v-list-item-title>{{item.title}}</v-list-item-title>
                                  </v-list-item-content>
                                </template>

                                <v-list-item color="#00174A"
                                  v-for="(child, i) in item.items"
                                  :key="i"
                                  @click="irReporteProducto(child.link, child.is)"
                                >
                                  <v-list-item-title v-text="child.title"></v-list-item-title>
                                </v-list-item>
                              </v-list-group>
                          </v-list-group>



            </v-list>
            </div>
           
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    data(){
        return {
          Reportes: [
          {
            items: [
              { is: 1, title: 'Mis Contactos', link: 'https://app.inmoglobalve.com.ve/ReporteMisContactos.php?uid=' },
              { is: 2, title: 'Contactos Global', link: 'https://app.inmoglobalve.com.ve/ReporteClientesGlobal.php' },
            ],
            title: 'Contactos',
            },
            {
            items: [
              { is: 1, title: 'Mis Productos', link: 'https://app.inmoglobalve.com.ve/ReporteMisProductos.php?uid=' },
              { is: 2, title: 'Productos Global', link: 'https://app.inmoglobalve.com.ve/EnviarReporteProductos.php' },
            ],
            title: 'Productos',
            },
            
          ],
          selectedItem:1,
          closss:'Cerrar Sesión',
          itemsPerfil:[
            {icon: 'far fa-user-circle',
            text: 'Inicio',
            sitio: '/'}
            
          ],
            nav:true,
            perfilMenu:true,
            items: [
        {
          items: [
            { title: 'Añadir Contactos', link: '/Contacto/Nuevo' },
            { title: 'Mis Contactos', link: '/Contacto/MisContactos' },
            { title: 'Team Global', link: '/Contactos/TeamGlobal' },
          ],
          title: 'Contactos',
        },
        {
          items: [
            { title: 'Añadir Productos', link: '/Productos/Nuevo' },
            { title: 'Mis Productos', link: '/Productos/MisProductos' },
            { title: 'Productos Global', link: '/Productos/ProductosGlobal' },
          ],
          title: 'Productos',
        },
        {
          items: [
            { title: 'Añadir Actividades', link: '/actividades-nueva' },
            { title: 'Mis Actividades', link: '/actividades-mis-actividades' },
          ],
          title: 'Actividades',
        }
      ],      
      contactoAdmin: [
        {
          items: [
            { title: 'Añadir Contactos', link: '/contacto-nuevo' },
            { title: 'Mis Contactos', link: '/contacto-mis-contactos' },
            { title: 'Clientes Global', link: '/contacto-clientes-global' },
          ],
          title: 'Contactos',
        },
        {
          items: [
          { title: 'Añadir Productos', link: '/productos-nuevo' },
            { title: 'Mis Productos', link: '/productos-mis-productos' },
            { title: 'Productos Global', link: '/productos-productos-global' },
          ],
          title: 'Productos',
        },
        {
          items: [
            { title: 'Añadir Actividades', link: '/actividades-nueva' },
            { title: 'Mis Actividades', link: '/actividades-mis-actividades' },
            { title: 'Actividades Global', link: '/actividades-global' },
          ],
          title: 'Actividades',
        }       
      ],
      contactoAsesor: [
        {
          items: [
            { title: 'Añadir Contactos', link: '/contacto-nuevo' },
            { title: 'Mis Contactos', link: '/contacto-mis-contactos' },
          ],
          title: 'Contactos',
        },
        {
          items: [
            { title: 'Añadir Productos', link: '/productos-nuevo' },
            { title: 'Mis Productos', link: '/productos-mis-productos' },
            { title: 'Productos Global', link: '/productos-productos-global' },
          ],
          title: 'Productos',
        },
       {
          items: [
            { title: 'Añadir Actividades', link: '/actividades-nueva' },
            { title: 'Mis Actividades', link: '/actividades-mis-actividades' },
          ],
          title: 'Actividades',
        }
      ],
        }
    },
    methods:{
        ...mapActions(['signOut']),
        irSeccion(e){
          window.open(e, '_self')
        },
        irReporteProducto(a, e){
          if (e == 1) {
            window.open(`${a+this.usuario.uid}`, "_blank");
          }else if (e == 2 && this.usuario.rank == 1) {
            window.open(a, "_blank");
          }
        }
    },
    computed:{
        ...mapState(['usuario'])
    }
}
</script>