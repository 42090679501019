import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { getAuth } from "../firebase";  // Importar la autenticación de Firebase
import { getDatabase, ref, set, onValue, remove, onDisconnect } from "firebase/database"; // Importar Firebase Realtime Database

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    usuario: null,
    usuariosEnLinea: []
  },
  getters: {
    usuariosEnLinea: state => state.usuariosEnLinea,
  },
  mutations: {
    // Mutación para configurar el usuario
    setUsuario(state, e) { 
      state.usuario = e;

      // Firebase: Agregar el usuario a la lista de usuarios en línea
      if (e) {
        const db = getDatabase();
        const userRef = ref(db, `onlineUsers/${e.uid}`);
        set(userRef, {
          username: e.username || e.email,
          uid: e.uid
        });

        // Manejar desconexión automática
        onDisconnect(userRef).remove();

        // Eliminar al usuario cuando cierre la ventana o pestaña
        window.addEventListener('beforeunload', () => {
          remove(userRef);
        });
      }
    },
    
    // Mutación para establecer la lista de usuarios en línea
    setUsuariosEnLinea(state, usuarios) {
      state.usuariosEnLinea = usuarios;
    }
  },
  actions: {
    // Acción para detectar el usuario actual y configurarlo en el estado
    async detectarUsuario({ commit }) {
      try {
        const auth = getAuth();
        const usuario = auth.currentUser;
        
        if (usuario) {
          await axios.post('https://app.inmoglobalve.com.ve/php/login.php', {
            uid: usuario.uid
          }).then(res => {
            commit('setUsuario', res.data[0]);
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    // Acción para cerrar sesión y eliminar al usuario de la lista de usuarios en línea
    signOut({ commit, state }) {
      const db = getDatabase();
      const userRef = ref(db, `onlineUsers/${state.usuario.uid}`);
      
      // Eliminar el usuario de la lista en línea
      remove(userRef)
        .then(() => {
          getAuth().signOut();
          commit('setUsuario', null);
          window.open("/", "_self");
        })
        .catch((error) => {
          console.error('Error al cerrar sesión y eliminar usuario en línea:', error);
        });
    },

    // Acción para registrar las actividades de los usuarios
    logActivity({ action, userId }) {
      axios.post('https://app.inmoglobalve.com.ve/php/log-activity.php', {
          user_id: userId,
          action: action
      })
      .then(response => {
          console.log('Movimiento registrado:', response.data);
      })
      .catch(error => {
          console.error('Error registrando movimiento:', error);
      });
    },

    // Acción para obtener usuarios en línea en tiempo real
    obtenerUsuariosEnLinea({ commit }) {
      const db = getDatabase();
      const usersRef = ref(db, 'onlineUsers');
      
      onValue(usersRef, (snapshot) => {
        const usuariosEnLinea = [];
        snapshot.forEach(childSnapshot => {
          usuariosEnLinea.push(childSnapshot.val());
        });
        commit('setUsuariosEnLinea', usuariosEnLinea);
      });
    }
  },
  modules: {
  }
});
